import './IcecreamList.scss';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Card } from '../../components/card/Card';
import { Sidescroll, SidescrollChild } from '../../components/common/sidescroll/Sidescroll';
import { Brand } from '../brand/Brand';
import { StarRatingInline } from '../star-rating/StarRating';
import { Image } from '../../components/image/Image';
import { Link } from 'react-router-dom';

export const IcecreamTopList = ({ icecreams }) => {
  const location = useLocation();
  const [visibles, setVisibles] = useState({});
  const [intersectionObserver, setIntersectionObserver] = useState();

  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const inViewport = (entries) => {
        entries.forEach((entry) => {
          setVisibles((prev) => {
            return {
              ...prev,
              [entry.target.id]: entry.isIntersecting,
            };
          });
        });
      };

      const obs = new IntersectionObserver(inViewport, {
        threshold: 0.3,
        rootMargin: '0px',
      });
      setIntersectionObserver(obs);
      return () => obs.disconnect();
    }
  }, [icecreams]);

  useEffect(() => {
    const initial = intersectionObserver === undefined;
    setVisibles(
      icecreams.reduce((map, obj) => {
        map[obj.id] = initial;
        return map;
      }, {})
    );
  }, [icecreams, intersectionObserver]);

  return (
    <ol className='icecream-top-list'>
      {icecreams.map((icecream, index) => (
        <IcecreamListItem
          key={icecream.id}
          icecream={icecream}
          rank={index + 1}
          intersectionObserver={intersectionObserver}
          visible={visibles[icecream.id]}
          location={location}
        />
      ))}
    </ol>
  );
};

export const IcecreamListItem = React.memo(({ icecream, rank, intersectionObserver, visible, location }) => {
  const ref = useRef(null);
  useEffect(() => {
    const element = ref.current;
    if (intersectionObserver && element) {
      intersectionObserver.observe(element);
      return () => intersectionObserver.unobserve(element);
    }
  }, [intersectionObserver]);

  return (
    <li>
      <CSSTransition nodeRef={ref} timeout={400} in={visible}>
        <div id={icecream.id} ref={ref} className='animate-in-view'>
          <Card
            action={{
              to: '/glass/' + icecream.id,
              state: { from: location },
            }}
          >
            <div className='icecream-list-item'>
              <div className='icecream-list-item__rank'>{rank}</div>
              <div className='icecream-list-item__avatar'>
                <Image icecream={icecream} small={true} lazy={rank > 10} />
              </div>
              <div className='icecream-list-item__header'>
                <h2>{icecream.name}</h2>
                <div>
                  <Brand brand={icecream.brand} />
                  <StarRatingInline rating={icecream.avgRating} numRating={icecream.numRatings} />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </CSSTransition>
    </li>
  );
});

export const IcecreamSearchList = ({ icecreams }) => {
  return (
    <ul className='icecream-search-list'>
      {icecreams.map((icecream, index) => (
        <li key={index}>
          <IcecreamSearchListLink icecream={icecream} />
        </li>
      ))}
    </ul>
  );
};

export const IcecreamSearchListLink = ({ icecream }) => {
  return (
    <Card
      className={'icecream-feature-card'}
      action={{
        to: '/glass/' + icecream.id,
      }}
    >
      <div className='icecream-feature-card__image'>
        <Image icecream={icecream} lazy='true' />
      </div>
      <div className='icecream-feature-card__title'>
        <h3>{icecream.name}</h3>
        <Brand brand={icecream.brand} />
      </div>
    </Card>
  );
};

export const IcecreamNewsList = ({ icecreams }) => {
  return (
    <Sidescroll>
      <ul className='icecream-news-list'>
        {icecreams.map((icecream, index) => (
          <li key={index}>
            <SidescrollChild>
              <Link className='news-card' to={'/glass/' + icecream.id}>
                <Image icecream={icecream} />
              </Link>
            </SidescrollChild>
          </li>
        ))}
      </ul>
    </Sidescroll>
  );
};

export const IcecreamFeatureCard = ({ icecream }) => {
  return (
    <Card
      className={'icecream-feature-card'}
      action={{
        to: '/glass/' + icecream.id,
      }}
    >
      <div className='icecream-feature-card__image'>
        <Image icecream={icecream} />
        <Brand brand={icecream.brand} />
      </div>
    </Card>
  );
};
