import './Commons.scss';
import React from 'react';

export const Container = ({ children, style, small = false }) => {
  return (
    <div className={`container ${small ? 'container-small' : ''}`} style={style}>
      {children}
    </div>
  );
};
