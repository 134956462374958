import React, { useState, useEffect } from 'react';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';

export const FirebaseContext = React.createContext(null);

export const FirebaseProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        setUser(user);
        setIsLoading(false);
      },
      (error) => setError(error)
    );

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (user) {
      user
        .getIdTokenResult()
        .then((tokenResult) => {
          setIsAdmin(tokenResult.claims.admin);
        })
        .catch(() => setIsAdmin(false));
    } else {
      setIsAdmin(false);
    }
  }, [user]);

  const value = {
    user: user,
    isAdmin: isAdmin,
    isLoading: isLoading,
    error: error,
  };

  return <FirebaseContext.Provider value={value}>{children}</FirebaseContext.Provider>;
};
