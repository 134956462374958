import { useEffect, useRef, useState } from 'react';

export const RenderOnEnter = ({ children }) => {
  const [ref, setRef] = useState();
  const [inView] = useEnterViewport(ref, 0);
  return inView ? children : <div ref={(newRef) => setRef(newRef)}></div>;
};

export const useEnterViewport = (element, thredshold = 0.5) => {
  const [inView, setInView] = useState(false);
  const observerRef = useRef();

  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const inViewport = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setInView(true);
            obs.disconnect();
          }
        });
      };

      const obs = new IntersectionObserver(inViewport, { threshold: thredshold, rootMargin: '0px' });
      observerRef.current = obs;

      return () => {
        obs.disconnect();
      };
    } else {
      setInView(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ('IntersectionObserver' in window && element) {
      observerRef.current.observe(element);
      return () => {
        observerRef.current.unobserve(element);
      };
    }
  }, [element]);

  return [inView];
};
