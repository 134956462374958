import { Ratings } from './Ratings';

export class Review {
  constructor(id, icecreamId, userId, userName, timestamp, location, text, rating) {
    this.id = id;
    this.icecreamId = icecreamId;
    this.userId = userId;
    this.userName = userName || '';
    this.timestamp = timestamp || Date.now();
    this.location = location;
    this.text = text;
    this.rating = rating;
  }
}

export const reviewConverter = {
  toFirestore: (review) => {
    return {
      userId: review.userId,
      userName: review.userName,
      timestamp: review.timestamp,
      text: review.text,
      hasText: review.text && review.text.length > 0,
      rating: review.rating / Ratings.MAX_RATING,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new Review(
      snapshot.id,
      snapshot.ref.parent.parent.id,
      data.userId,
      data.userName,
      new Date(data.timestamp),
      undefined,
      data.text,
      data.rating * Ratings.MAX_RATING
    );
  },
};
