import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container } from '../../components/common/Common';
import { GenericPage } from '../base/Base';
import { Header } from '../../components/header/Header';

export const System404 = () => {
  return (
    <GenericPage>
      <Helmet>
        <meta name='robots' content='noindex' />
      </Helmet>
      <Header>
        <h1>404</h1>
      </Header>
      <Container>
        <main>
          <h2>Sidan hittades inte</h2>
        </main>
      </Container>
    </GenericPage>
  );
};
