import { IceCream } from './IceCream';
import { Ratings } from './Ratings';

export class App {
  constructor(icecreams) {
    this.icecreams = icecreams;
  }
}

export const appConverter = {
  fromJson: (data) => {
    const icecreams =
      data?.map(
        ({ id, name, brand, image, tags, isNew, numRatings, avgRating }) =>
          new IceCream(id, name, brand, tags, image, isNew, numRatings, avgRating * Ratings.MAX_RATING)
      ) || [];
    return new App(icecreams);
  },
};
