import { useEffect, useContext } from 'react';
import { StoreContext } from '../../store/Store';
import { appConverter } from '../../models/App';

export const useApp = () => {
  const { state, dispatch } = useContext(StoreContext);
  const { isLoading, error, lastUpdated } = state.app;

  const invalid = new Date() - lastUpdated > 1000 * 60 * 5;

  useEffect(() => {
    if (isLoading) {
      // wait
    } else if (state.icecreams.ids.length > 0 && !invalid) {
      // use existing data
    } else {
      dispatch({ type: 'APP_FETCH' });
      fetch(window.location.hostname === 'localhost' ? 'http://localhost:5000/app' : '/app', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const app = appConverter.fromJson(data);
          if (data) {
            dispatch({ type: 'APP_SET', payload: app });
          } else {
            dispatch({ type: 'APP_ERROR', payload: { message: '' } });
          }
        })
        .catch((error) => dispatch({ type: 'APP_ERROR', payload: error }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    state: state,
    isLoading: isLoading,
    error: error,
  };
};
