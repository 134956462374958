import { iceCreamConverter } from '../../models/IceCream';
import { useMutation } from './UseFirestore';
import { useApp } from './UseApp';

export const useIcecream = (id) => {
  const { state, isLoading, error } = useApp();
  const { mutate: add, isLoading: addIsLoading, error: addError } = useMutation('add', {
    collection: 'icecreams',
    converter: iceCreamConverter,
  });
  const { mutate: update, isLoading: updateIsLoading, error: updateError } = useMutation('update', {
    collection: 'icecreams',
    id: id,
  });
  const { mutate: remove, isLoading: removeIsLoading, error: removeError } = useMutation('delete', {
    collection: 'icecreams',
    id: id,
  });

  return {
    icecream: state.icecreams.entities[id],
    add: add,
    update: update,
    remove: remove,
    isLoading: isLoading || addIsLoading || updateIsLoading || removeIsLoading,
    error: error || addError || updateError || removeError,
  };
};
