import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../firebase/hooks/UseAuth';

export const UserRoute = ({ children }) => {
  const { user, isLoading } = useAuth();
  const location = useLocation();

  return isLoading ? <></> : user ? children : <Navigate to='/loggain' state={{ from: location }} replace={false} />;
};

export const AdminRoute = ({ children }) => {
  const { isAdmin, isLoading } = useAuth();
  const location = useLocation();

  return isLoading ? <></> : isAdmin ? children : <Navigate to='/loggain' state={{ from: location }} replace={false} />;
};
