import './Navigation.scss';
import React from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faIceCream, faCrown, faUserCircle, faTools } from '@fortawesome/free-solid-svg-icons';
import { Container } from '../common/Common';
import { useAuth } from '../../firebase/hooks/UseAuth';

export const Navigation = () => {
  const { isAdmin } = useAuth();

  return (
    <nav className='navigation'>
      <Container>
        <ul>
          <li>
            <NavLink to='/'>
              <span>Topplista</span>
              <span className='icon'>
                <FontAwesomeIcon icon={faCrown}></FontAwesomeIcon>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to='/glass'>
              <span>Glass</span>
              <span className='icon'>
                <FontAwesomeIcon icon={faIceCream}></FontAwesomeIcon>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to='/anvandare'>
              <span>Profil</span>
              <span className='icon'>
                <FontAwesomeIcon icon={faUserCircle}></FontAwesomeIcon>
              </span>
            </NavLink>
          </li>
          {isAdmin && (
            <li>
              <NavLink to='/admin/glass'>
                <span>Admin</span>
                <span className='icon'>
                  <FontAwesomeIcon icon={faTools}></FontAwesomeIcon>
                </span>
              </NavLink>
            </li>
          )}
        </ul>
      </Container>
    </nav>
  );
};

export const GoUpNavigation = ({ label, parent }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const goUp = () => {
    const to = location.state?.from?.pathname || parent;
    navigate(to, { state: { from: location.state?.from?.state?.from } });
  };

  return (
    <nav className='navigation navigation--back'>
      <Container>
        <button className='active' onClick={goUp}>
          <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
        </button>
        <span>{label}</span>
      </Container>
    </nav>
  );
};
