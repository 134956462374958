import './App.scss';
import React from 'react';
import { FirebaseProvider } from './firebase/context';
import { Router } from './routes/Router';
import { StoreProvider } from './store/Store';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <StoreProvider>
      <FirebaseProvider>
        <HelmetProvider>
          <div className='app'>
            <Router />
          </div>
        </HelmetProvider>
      </FirebaseProvider>
    </StoreProvider>
  );
}

export default App;
