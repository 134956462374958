import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { Button, Notification } from '../../components/button/Button';
import { Toggle, Segment } from '../../components/input/Input';
import { Modal } from '../../components/modal/Modal';

export const TopListFilters = ({ onSave, filters: currentFilter }) => {
  const [isOpen, setOpen] = useState(false);
  const [filters, setFilters] = useState(currentFilter);

  useEffect(() => {
    setFilters(currentFilter);
  }, [currentFilter]);

  const onChangeTags = (tag, checked) => {
    setFilters((prev) => ({
      ...prev,
      tags: {
        ...prev.tags,
        [tag]: checked,
      },
    }));
  };

  const onChangeMinRatings = (checked) => {
    setFilters((prev) => ({
      ...prev,
      minRatings: checked,
    }));
  };

  const onChangeSort = (checked) => {
    setFilters((prev) => ({
      ...prev,
      sortByMostRatings: checked,
    }));
  };

  const saveChanges = () => {
    onSave(filters);
    setOpen(false);
  };

  const discardChanges = () => {
    setFilters(currentFilter);
    setOpen(false);
  };

  const appliedFilters = () => {
    let sum = 0;
    for (let key in filters.tags) {
      if (filters.tags[key]) {
        sum++;
      }
    }
    if (sum === Object.keys(filters.tags).length) {
      sum = 0;
    }
    if (filters.sortByMostRatings) {
      sum++;
    }
    if (filters.minRatings) {
      sum++;
    }
    return sum;
  };

  const noTagActive = () => {
    return Object.keys(filters.tags).every((tag) => filters.tags[tag] === false);
  };

  const sum = appliedFilters();

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        {sum > 0 && <Notification number={sum} />}
        <FontAwesomeIcon icon={faSlidersH}></FontAwesomeIcon>
      </Button>
      <Modal
        header={<h1>Filtrera</h1>}
        actions={
          <Button onClick={saveChanges} disabled={noTagActive()}>
            Visa
          </Button>
        }
        isOpen={isOpen}
        onRequestClose={discardChanges}
        body={
          <div className='toplist-modal-filters'>
            <SortByToggle checked={filters.sortByMostRatings} onChange={onChangeSort} />
            <SortToggle header='Isglass' tag='ice' filters={filters} onChange={onChangeTags} />
            <SortToggle header='Pinnglass' tag='stick' filters={filters} onChange={onChangeTags} />
            <SortToggle header='Strut' tag='cone' filters={filters} onChange={onChangeTags} />
            <SortToggle header='Övrigt' tag='other' filters={filters} onChange={onChangeTags} />
            <MinRatingsToggle checked={filters.minRatings} onChange={onChangeMinRatings} />
          </div>
        }
      />
    </>
  );
};

const SortToggle = ({ header, tag, filters, disabled, onChange }) => {
  return (
    <div className='row'>
      <h3>{header}</h3>
      <Toggle
        checked={filters.tags[tag]}
        disabled={disabled}
        onChange={(event) => onChange(tag, event.target.checked)}
      />
    </div>
  );
};

const MinRatingsToggle = ({ onChange, checked }) => {
  return (
    <>
      <div className='row'>
        <h3>Betygsgräns</h3>
        <Toggle checked={checked} onChange={(event) => onChange(event.target.checked)} />
      </div>
      <span style={{ position: 'relative', fontSize: '0.8rem', top: '-1rem' }}>Dölj glassar med färre än 5 betyg</span>
    </>
  );
};

const SortByOptions = ['Högst betyg', 'Flest röster'];

const SortByToggle = ({ onChange, checked }) => {
  return (
    <>
      <div>
        <h3 style={{ marginBottom: '0.5rem' }}>Sortera på</h3>
        <Segment
          name={'sortby_segment'}
          values={SortByOptions}
          selected={checked ? SortByOptions[1] : SortByOptions[0]}
          onChange={(event) => onChange(event === SortByOptions[1])}
        />
      </div>
    </>
  );
};
