import './IcecreamHeader.scss';
import React from 'react';
import { Image } from '../../components/image/Image';
import { StarRating } from '../star-rating/StarRating';
import { Header } from '../header/Header';

export const IcecreamHeader = ({ icecream, rating = false }) => {
  return (
    <div className='icecream-header'>
      <Header>
        <h1>{icecream?.name || 'Namn'}</h1>
      </Header>
      <IcecreamAvatar icecream={icecream} rating={rating} animated={true} />
    </div>
  );
};

export const IcecreamAvatar = ({ animated, icecream, rating = false }) => {
  return (
    <div className={`icecream-avatar ${animated ? 'icecream-avatar--animated' : ''}`}>
      <Image icecream={icecream} />
      {rating && <StarRating value={icecream?.avgRating} />}
    </div>
  );
};
