import './Image.scss';
import React from 'react';
import { useSrcSet } from '../../hooks/useSrcset';

export const Image = ({ icecream, small, lazy, ...props }) => {
  const { src, srcSet, sizes, type } = useSrcSet(icecream, small);
  const styles = {};

  return (
    <picture className='image' style={styles}>
      {srcSet && <source srcSet={srcSet} sizes={sizes} type={type} />}
      {src && <img width='160' height='160' src={src} {...props} alt='' loading={lazy ? 'lazy' : 'eager'} />}
    </picture>
  );
};
