export class Ratings {
  static MAX_RATING = 5;

  static format(rating) {
    return rating.toFixed(2);
  }

  static addReview(currentRating, review, numRatings) {
    const average = (numRatings, icecreamRating, reviewRating) => {
      return (numRatings * icecreamRating + reviewRating) / (numRatings + 1);
    };
    return average(numRatings, currentRating, review.rating);
  }
}
