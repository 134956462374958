import './Loading.scss';
import React from 'react';

export const Loading = ({ loading }) => {
  if (loading) {
    return (
      <div className='loading'>
        <div className='loading__dot'></div>
        <div className='loading__dot'></div>
        <div className='loading__dot'></div>
      </div>
    );
  }
  return <></>;
};
