import './Input.scss';
import React from 'react';

export const Input = (props) => {
  return <input className='input' {...props} />;
};

export const Toggle = ({ onChange, checked, disabled }) => {
  return (
    <label className='switch'>
      <input type='checkbox' onChange={onChange} checked={checked} disabled={disabled} />
      <span className='slider'></span>
    </label>
  );
};

export const Segment = ({ onChange, values, selected, name }) => {
  return (
    <div className='segment'>
      {values.map((value, index) => {
        const id = name + '_' + index;
        const isChecked = value === selected;
        return (
          <div key={id}>
            <input
              type='radio'
              name={name}
              value={value}
              id={id}
              checked={isChecked}
              onChange={() => onChange(value)}
            />
            <label htmlFor={id} className='button button--small'>
              {value}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export const Textarea = (props) => {
  return <textarea {...props} />;
};
