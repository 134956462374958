import './LoginPage.scss';
import React, { useEffect, useState } from 'react';
import { useAuth, useProfile, useProfileCreatedListener } from '../../firebase/hooks/UseAuth';
import { GenericPage } from '../base/Base';
import { Loading } from '../../components/loading/Loading';
import { auth } from '../../firebase/firebase';
import { Header } from '../../components/header/Header';
import { useNavigate, useLocation } from 'react-router';
import { Input } from '../../components/input/Input';
import { Button } from '../../components/button/Button';
import { ModalError } from '../../components/modal/Modal';
import { Container } from '../../components/common/Common';
import { EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

const PREOVIS_PAGE_KEY = 'glassduellen:login:to-page';

export const LoginPage = () => {
  const { user, isLoading } = useAuth();

  return (
    <GenericPage className='login-page'>
      <Header>
        <h1>Logga in</h1>
      </Header>
      <main>
        <Loading loading={isLoading} />
        {!isLoading && !user && <LoginLinks auth={auth} />}
      </main>
    </GenericPage>
  );
};

const LoginLinks = ({ auth }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
    ui.start('#firebaseui-auth-container', uiConfig);
  }, []);

  useEffect(() => {
    const from = location.state?.from;
    if (from) {
      sessionStorage.setItem(PREOVIS_PAGE_KEY, JSON.stringify(from));
    }
  }, [location]);

  // https://github.com/firebase/firebaseui-web
  const uiConfig = {
    signInFlow: 'redirect',
    signInOptions: [EmailAuthProvider.PROVIDER_ID, GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => {
        const { isNewUser } = authResult.additionalUserInfo;
        const from = JSON.parse(sessionStorage.getItem(PREOVIS_PAGE_KEY));
        if (isNewUser) {
          navigate('/registrera', { replace: false, state: { from } });
        } else {
          navigate(from?.pathname || '/', { replace: false, state: { from: from?.state?.from } });
        }

        sessionStorage.removeItem(PREOVIS_PAGE_KEY);
        return false;
      },
    },
    tosUrl: './anvandarvillkor',
    privacyPolicyUrl: './integritetspolicy',
  };

  return <div id='firebaseui-auth-container'></div>;
};

export const NewUserPage = () => {
  const { user, exists, isLoading, error } = useProfileCreatedListener();

  return (
    <GenericPage className='login-page'>
      <Header>
        <h1>Registrera</h1>
      </Header>
      <main>
        <Loading loading={isLoading} />
        <Container>{!isLoading && exists && <ProfileSettings user={user} />}</Container>
      </main>
      <ModalError error={error} />
    </GenericPage>
  );
};

const ProfileSettings = ({ user }) => {
  const { update, isLoading, error } = useProfile();
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState(user.displayName);
  const [anonymous] = useState(false);
  const provider = user.providerData[0]?.providerId || '';

  return (
    <div className='profile-settings'>
      <h3>Alias</h3>
      <div>
        <Input
          value={name}
          onChange={(event) => setName(event.target.value)}
          maxLength={40}
          disabled={isLoading}
        ></Input>

        {/* <h3>Anonym</h3>
        <Toggle onChange={(event) => setAnonymous(event.target.checked)} />
        <div>
          <small>Dölj ditt namn på recensioner</small>
        </div> */}

        <Button
          onClick={() => {
            const next = () => {
              const from = location.state?.from;
              navigate(from?.pathname || '/', { replace: false, state: { from: from?.state?.from } });
            };
            if (provider === 'password' || name !== user.displayName || anonymous) {
              update({ alias: name, anonymous }).then((success) => {
                if (success) {
                  next();
                }
              });
            } else {
              next();
            }
          }}
          disabled={!name}
          loading={isLoading}
        >
          Fortsätt
        </Button>
        <ModalError error={error} />
      </div>
    </div>
  );
};
