import { initializeApp } from 'firebase/app';
//import 'firebase/analytics';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from 'firebase/auth';

// admin
//import 'firebase/storage';
//import 'firebase/functions';
//import 'firebase/app-check';

const firebaseConfig = {
  apiKey: 'AIzaSyBkVcXF1nJYKe8mFE-LemVU_g2ar_XrIAU',
  authDomain: 'glassduellen.se',
  projectId: 'ice-off',
  storageBucket: 'ice-off.appspot.com',
  messagingSenderId: '813612239879',
  appId: '1:813612239879:web:400e20dce554140a59aea2',
  measurementId: 'G-EZSQ44WFWL',
};
const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const firestore = getFirestore();
//export const functions = app.functions('europe-west1');
//export const storage = app.storage();
export default app;

// const appCheck = app.appCheck();
// appCheck.activate('6LdK6-MaAAAAANoIR72LzO7fTt1vDFN4kBdIG0Y3');

if (window.location.hostname === 'localhost') {
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectAuthEmulator(auth, 'http://localhost:9099');
  //functions.useEmulator('localhost', 5001);
  //storage.useEmulator('localhost', 9199);
  console.log('%cUse firestore emulator on 8080', 'color:green');
} else {
  //console.log('%cUse firestore production', 'color:yellow');
}
