import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { UserRoute, AdminRoute } from './ProtectedRoute';
import { TopListPage } from '../pages/toplist/TopListPage.js';
import { ReviewPage } from '../pages/review/ReviewPage.js';
import { LoginPage, NewUserPage } from '../pages/login/LoginPage';
import { UserPage } from '../pages/user/UserPage';
import { MyReviewsPage } from '../pages/review/MyReviewsPage';
import { IcecreamPage } from '../pages/icecream/IcecreamPage';
import { SearchPage } from '../pages/icecreams/SearchPage';
import { PrivacyPolicyPage } from '../pages/info/PrivacyPolicyPage';
import { TermsOfServicePage } from '../pages/info/TermsOfServicePage';
import { System404 } from '../pages/system/404';

const AdminRouter = lazy(() => import('./AdminRouter'));

export const Router = () => {
  return (
    <BrowserRouter>
      <ResetScrollOnNavigation />
      <Routes>
        <Route path='/' element={<TopListPage />} />
        <Route path='/glass' element={<SearchPage />} />
        <Route path='/glass/:id' element={<IcecreamPage />} />
        <Route
          path='/glass/:id/betygsatt'
          element={
            <UserRoute>
              <ReviewPage />
            </UserRoute>
          }
        />

        <Route
          path='/anvandare'
          element={
            <UserRoute>
              <UserPage />
            </UserRoute>
          }
        />
        <Route
          path='/anvandare/recensioner'
          element={
            <UserRoute>
              <MyReviewsPage />
            </UserRoute>
          }
        />

        <Route path='/loggain' element={<LoginPage />} />
        <Route path='/registrera' element={<NewUserPage />} />
        <Route path='/integritetspolicy' element={<PrivacyPolicyPage />} />
        <Route path='/anvandarvillkor' element={<TermsOfServicePage />} />

        <Route path='*' element={<System404 />} />

        <Route
          path='/admin/*'
          element={
            <AdminRoute>
              <Suspense fallback={<div>FALLBACK</div>}>
                <AdminRouter />
              </Suspense>
            </AdminRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export const ResetScrollOnNavigation = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return <></>;
};
