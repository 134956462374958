import './ReviewList.scss';
import React, { useState } from 'react';
import { useLatestReviews } from '../../firebase/hooks/UseReview';
import { Card } from '../card/Card';
import { StarRatingInline } from '../star-rating/StarRating';
import { Loading } from '../loading/Loading';
import { useIcecream } from '../../firebase/hooks/UseIcecream';
import { Button } from '../../components/button/Button';
import { Image } from '../../components/image/Image';
import { timeSince } from './utility';
import { Modal } from '../modal/Modal';

export const ReviewList = ({ reviews }) => {
  const [modal, setModal] = useState();

  return (
    <>
      <ul className='review-list'>
        {reviews &&
          reviews.map((review, index) => (
            <li key={index}>
              <Review review={review} onClick={(review, icecream) => setModal([review, icecream])} />
            </li>
          ))}
      </ul>
      <ReviewModal review={modal?.[0]} icecream={modal?.[1]} onClose={() => setModal(undefined)}></ReviewModal>
    </>
  );
};

export const ReviewModal = ({ review, icecream, onClose }) => {
  return (
    <Modal
      header={<h1>{icecream?.name}</h1>}
      body={
        review && (
          <div>
            <div className='review-comment'>
              <p className='review-comment__text'>
                <StarRatingInline rating={review.rating} />
                {review.text}
              </p>
              <div className='review-comment__info'>
                <span className='review-comment__name'>{review.userName}</span>
                <time dateTime={review.timestamp}>{timeSince(review.timestamp)}</time>
              </div>
            </div>
          </div>
        )
      }
      isOpen={!!review}
      onRequestClose={() => onClose()}
    ></Modal>
  );
};

const Review = ({ review, onClick }) => {
  const { icecream } = useIcecream(review.icecreamId);

  return (
    <Card className='review-card' onClick={() => onClick(review, icecream)}>
      <div className='review-card__image'>
        <Image icecream={icecream} lazy='true' />
      </div>
    </Card>
  );
};

export const LatestReviewsList = ({ id }) => {
  const { reviews, isLoading, error, next } = useLatestReviews(id);

  return (
    <div className='review-comment-list'>
      <Loading loading={reviews?.length === 0 && isLoading} />
      {reviews?.map((review) => (
        <LatestReviewCard review={review} key={review.id} />
      ))}
      {!isLoading && reviews?.length === 0 && <p>Ingen har kommenterat den här glassen ännu.</p>}
      {next && (
        <Button onClick={() => next()} loading={isLoading}>
          Visa fler
        </Button>
      )}
      {error && <p>Något gick fel.</p>}
    </div>
  );
};

const LatestReviewCard = ({ review }) => {
  return (
    <Card>
      <div className='review-comment'>
        <p className='review-comment__text'>
          <StarRatingInline rating={review.rating} />
          {review.text}
        </p>
        <div className='review-comment__info'>
          <span className='review-comment__name'>{review.userName}</span>
          <time dateTime={review.timestamp}>{timeSince(review.timestamp)}</time>
        </div>
      </div>
    </Card>
  );
};
