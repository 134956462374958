import './TopListPage.scss';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container } from '../../components/common/Common';
import { useToplist } from '../../firebase/hooks/UseIcecreams';
import { Loading } from '../../components/loading/Loading';
import { ModalError } from '../../components/modal/Modal';
import { GenericPage } from '../base/Base';
import { Header } from '../../components/header/Header';
import { IcecreamTopList } from '../../components/icecream/IcecreamList';
import { TopListFilters } from './TopListFilters';

export const TopListPage = () => {
  const { icecreams, isLoading, error, filters, setFilters } = useToplist();

  return (
    <GenericPage className='top-list-page'>
      <Helmet>
        <title>{`Glasstest ${new Date().getFullYear()} | Glassduellen`}</title>
        <meta
          name='description'
          content={`Upptäck nya smaker och betygsätt din favoritglass i årets stora glasstest! I topplistan hittar du recensioner och betyg från andra glassälskare.`}
        />
      </Helmet>
      <Header avatar={'rebecka'}>
        <h1>Glassduellen</h1>
        <span>Bäst glass {new Date().getFullYear()}</span>
      </Header>

      <main>
        <Container>
          <div className='top-list-page__top'>
            <h2>Topplista</h2>
            <TopListFilters onSave={setFilters} filters={filters} />
          </div>
          <Loading loading={isLoading} />
          {icecreams.length > 0 && <IcecreamTopList icecreams={icecreams} />}
        </Container>
      </main>

      <ModalError
        error={error}
        body={
          <>
            <p>Något gick fel.</p>
            <p>&nbsp;</p>
            <p>Pröva igen om en liten stund.</p>
          </>
        }
      />
    </GenericPage>
  );
};
