import './Card.scss';
import React from 'react';
import { Link } from 'react-router-dom';

export const Card = ({ children, action, onClick, className, ...rest }) => {
  const card = (
    <div className={'card ' + className} onClick={() => onClick && onClick()} {...rest}>
      {children}
    </div>
  );

  if (action) {
    return (
      <Link className='card__link' to={action.to} state={action.state}>
        {card}
      </Link>
    );
  } else {
    return card;
  }
};
