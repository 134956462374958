import React, { useReducer } from 'react';

export const StoreContext = React.createContext(null);

const INITIAL_STATE = {
  app: {
    isLoading: false,
    error: undefined,
    lastUpdated: undefined,
  },
  user: {
    isLoading: false,
    error: undefined,
    id: undefined,
    alias: undefined,
    anonymous: undefined,
    totalReviews: undefined,
    initialized: false,
  },
  icecreams: {
    ids: [],
    entities: {},
  },
  toplist: {
    filters: {
      minRatings: false,
      sortByMostRatings: false,
      tags: { ice: true, stick: true, cone: true, other: true },
    },
  },
  userpage: {
    isLoading: false,
    error: undefined,
    best: undefined,
    worst: undefined,
  },
};

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    const { type, payload } = action;
    switch (type) {
      case 'APP_SET':
        const icecreams = payload.icecreams.reduce(
          (dictionary, icecream) => (dictionary[icecream.id] = icecream) && dictionary,
          {}
        );
        return {
          ...state,
          app: {
            ...state.app,
            isLoading: false,
            error: undefined,
          },
          icecreams: {
            ids: payload.icecreams.map((icecream) => icecream.id),
            entities: icecreams,
          },
        };
      case 'APP_FETCH':
        return {
          ...state,
          app: {
            isLoading: true,
            lastUpdated: new Date(),
          },
        };
      case 'APP_ERROR':
        return {
          ...state,
          app: {
            ...state.app,
            isLoading: false,
            error: payload,
          },
        };
      case 'CREATE_ICECREAM_REIVEW':
        return {
          ...state,
          icecreams: {
            ids: state.icecreams.ids,
            entities: {
              ...state.icecreams.entities,
              [payload.id]: payload,
            },
          },
          user: {
            ...state.user,
            totalReviews: state.user.totalReviews + 1,
          },
          userpage: {
            ...state.userpage,
            best: undefined,
            worst: undefined,
          },
        };
      case 'USER_SET':
        return {
          ...state,
          user: {
            isLoading: false,
            error: undefined,
            id: payload.id,
            anonymous: payload.anonymous,
            totalReviews: payload.totalReviews ?? 0,
            alias: payload.alias,
            initialized: true,
          },
        };
      case 'USER_UPDATE':
        return {
          ...state,
          user: {
            ...state.user,
            ...payload,
          },
        };
      case 'USER_FETCH':
        return {
          ...state,
          user: {
            ...state.user,
            isLoading: true,
          },
        };
      case 'USER_ERROR':
        return {
          ...state,
          user: {
            ...state.user,
            isLoading: false,
            error: payload,
          },
        };
      case 'TOPLIST_FILTERS_SET':
        return {
          ...state,
          toplist: {
            filters: payload,
          },
        };
      case 'USERPAGE_SET':
        return {
          ...state,
          userpage: {
            best: payload.best,
            worst: payload.worst,
            isLoading: false,
            error: undefined,
          },
        };
      case 'USERPAGE_FETCH':
        return {
          ...state,
          userpage: {
            ...state.userpage,
            isLoading: true,
          },
        };
      case 'USERPAGE_ERROR':
        return {
          ...state,
          userpage: {
            ...state.userpage,
            isLoading: false,
            error: payload,
          },
        };
      default:
        throw new Error(`Invalid store action type: ${type}`);
    }
  }, INITIAL_STATE);

  return (
    <StoreContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
