import './Header.scss';
import React, { useRef } from 'react';
import { Mountains } from '../svg/Svg';
import { Container } from '../common/Common';
import { CSSTransition } from 'react-transition-group';

export const Header = ({ children, avatar, left }) => {
  const ref = useRef(null);

  return (
    <CSSTransition nodeRef={ref} appear={true} in={true} timeout={750} classNames='fade-in'>
      <header className='header' ref={ref}>
        <Container>{children}</Container>
        <Mountains />
        {avatar && <HeaderAvatar img={avatar} left={left} />}
      </header>
    </CSSTransition>
  );
};

export const HeaderAvatar = ({ img, left = false }) => {
  const basePath = `/assets/images/avatars/${img}_`;

  return (
    <picture>
      <source
        type='image/webp'
        srcSet={`${basePath}300x300.webp 300w, ${basePath}600x600.webp 600w, ${basePath}900x900.webp 900w`}
        sizes='300px'
      />
      <img
        width='300'
        height='300'
        className={`avatar avatar--${img} ${left ? 'avatar--left' : ''}`}
        src={`${basePath}300x300.webp`}
        alt=''
      ></img>
    </picture>
  );
};

export const Footer = () => {
  return (
    <footer>
      <Container>
        <p>
          Kontakt: <a href='mailto:info@glassduellen.se'>info@glassduellen.se</a>
        </p>
        <small>&copy; Copyright {new Date().getFullYear()}, Glassduellen</small>
      </Container>
    </footer>
  );
};
