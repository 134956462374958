import './StarRating.scss';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarSolid, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';

export const StarRatingInline = ({ rating, numRating }) => {
  return (
    <span className='star-rating-inline'>
      <FontAwesomeIcon icon={faStarSolid}></FontAwesomeIcon>
      {rating?.toFixed(2)} {numRating && <span>({numRating})</span>}
    </span>
  );
};

export const StarRating = ({ value = 0, max = 5, editable = false, disabled, onChange }) => {
  const [rating, setRating] = useState(value);
  const [hover, setHover] = useState(0);

  useEffect(() => {
    setRating(value);
  }, [value]);

  const onChangeHandler = (value) => {
    onChange(value);
    setRating(value);
  };

  return (
    <div
      className={'star-rating' + (editable ? ' star-rating--editable' : '')}
      disabled={disabled}
      onMouseLeave={editable ? () => setHover(0) : undefined}
    >
      {[...Array(max).keys()].map((index) => {
        return (
          <Star
            key={index}
            value={(hover || rating) - index}
            onMouseEnter={editable ? () => setHover(index + 1) : undefined}
            onClick={editable ? () => onChangeHandler(index + 1) : undefined}
          />
        );
      })}
    </div>
  );
};

export const Star = ({ value, onClick, onMouseEnter }) => {
  let icon;
  if (value <= 0.25) {
    icon = faStarRegular;
  } else if (value >= 0.75) {
    icon = faStarSolid;
  } else {
    icon = faStarHalfAlt;
  }
  return <FontAwesomeIcon icon={icon} onClick={onClick} onMouseEnter={onMouseEnter}></FontAwesomeIcon>;
};
