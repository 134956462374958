import './SearchPage.scss';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { GenericPage } from '../base/Base';
import { useIcecreamSearch } from '../../firebase/hooks/UseIcecreams';
import { Modal, ModalError } from '../../components/modal/Modal';
import { Loading } from '../../components/loading/Loading';
import { Container } from '../../components/common/Common';
import { Header } from '../../components/header/Header';
import { Input } from '../../components/input/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMitten, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { IcecreamNewsList, IcecreamSearchList } from '../../components/icecream/IcecreamList';
import { Button, Notification } from '../../components/button/Button';
import { BrandFilter, BRANDS_ARRAY } from '../../components/brand/Brand';
import { CoffeeSection } from '../../components/svg/Svg';

export const SearchPage = () => {
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    brands: [],
  });
  const { icecreams, news, count, isLoading, error } = useIcecreamSearch(search, filters.brands);

  return (
    <GenericPage className='search-page'>
      <Helmet>
        <title>Sök glass | Glassduellen</title>
        <meta
          name='description'
          content={`Utforska årets glassnyheter ${new Date().getFullYear()} eller hitta en gammal favorit. Här kan du recensera glass från ${BRANDS_ARRAY.map(
            (b) => b.name
          ).join(' - ')}`}
        />
      </Helmet>
      <Header avatar={'max'} left={true}>
        <h1>Glass</h1>
        <span>I stora lass</span>
      </Header>

      <main>
        <CoffeeSection>
          <Container>
            <h2>Glassnyheter 2024</h2>
            <IcecreamNewsList icecreams={news} />
          </Container>
        </CoffeeSection>
        <Container>
          <section className='search-page__all'>
            <h2>Alla glassar</h2>
            <div className='search-page__search'>
              <Input
                type='search'
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                placeholder={`Sök bland ${count} glassar`}
              />
              <ModalFilters onSave={setFilters} filters={filters} />
            </div>
            <Loading loading={isLoading} />
            {!isLoading && (
              <span>
                {icecreams.length} {icecreams.length === 1 ? 'träff' : 'träffar'}
              </span>
            )}
            {!isLoading && search && icecreams.length === 0 && <NoHits search={search} />}
            <IcecreamSearchList icecreams={icecreams} />
          </section>
        </Container>
      </main>

      <ModalError
        error={error}
        body={
          <>
            <p>Något gick fel.</p>
            <p>&nbsp;</p>
            <p>Pröva igen om en liten stund.</p>
          </>
        }
      />
    </GenericPage>
  );
};

const NoHits = ({ search }) => {
  return (
    <div className='search-no-hits'>
      <div>
        <FontAwesomeIcon icon={faMitten} />
      </div>
      <span>"{search}" verkar inte finnas i frysen!</span>
    </div>
  );
};

const ModalFilters = ({ onSave, filters: currentFilter }) => {
  const [isOpen, setOpen] = useState(false);
  const [filters, setFilters] = useState({
    brands: [],
  });

  useEffect(() => {
    setFilters(currentFilter);
  }, [currentFilter]);

  const onChangeBrand = (brand, checked) => {
    setFilters((prev) => ({
      ...prev,
      brands: checked ? [...prev.brands, brand] : prev.brands.filter((id) => id !== brand),
    }));
  };

  const saveChanges = () => {
    onSave({ brands: filters.brands });
    setOpen(false);
  };

  const discardChanges = () => {
    setFilters(currentFilter);
    setOpen(false);
  };

  const sum = filters.brands.length;

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        {sum > 0 && <Notification number={sum} />}
        <FontAwesomeIcon icon={faSlidersH}></FontAwesomeIcon>
      </Button>
      <Modal
        header={<h1>Filtrera</h1>}
        actions={<Button onClick={saveChanges}>Visa resultat</Button>}
        isOpen={isOpen}
        onRequestClose={discardChanges}
        body={
          <div>
            <h3 style={{ marginBottom: '0.5rem' }}>Varumärke {sum > 0 && `(${sum})`}</h3>
            <BrandFilter value={filters.brands} onChange={onChangeBrand} />
          </div>
        }
      />
    </>
  );
};
