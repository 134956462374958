import './Base.scss';
import React from 'react';
import { GoUpNavigation, Navigation } from '../../components/navigation/Navigation';
import { Footer } from '../../components/header/Header';

export const GenericPage = ({ children, className }) => {
  return (
    <div className={`page ${className}`}>
      <Navigation></Navigation>
      {children}
      <Footer />
    </div>
  );
};

export const DetailPage = ({ children, label, parent, className }) => {
  return (
    <div className={`page ${className}`}>
      <GoUpNavigation label={label} parent={parent}></GoUpNavigation>
      {children}
      <Footer />
    </div>
  );
};
