import { useState, useEffect, useContext } from 'react';
import { firestore } from '../firebase';
import { collectionGroup, query as firestoreQuery, orderBy, where, collection, doc, limit } from 'firebase/firestore';
import { StoreContext } from '../../store/Store';
import { usePagination } from './UsePagination';
import { useDoc, useCollection, useMutation } from './UseFirestore';
import { reviewConverter } from '../../models/Review';

export const useReview = (icecreamId, reviewId) => {
  const [query] = useState(
    firestoreQuery(doc(doc(firestore, 'icecreams', icecreamId), 'reviews', reviewId).withConverter(reviewConverter))
  );
  const { data, isLoading, error } = useDoc(query);

  return {
    review: data,
    isLoading: isLoading,
    error: error,
  };
};

export const usePostReview = (userId, icecreamId) => {
  const { mutate: add, isLoading: addIsLoading, error: addError } = useMutation('set', {
    collection: `icecreams/${icecreamId}/reviews`,
    id: userId,
    converter: reviewConverter,
  });

  return {
    add: add,
    isLoading: addIsLoading,
    error: addError,
  };
};

export const useUserStats = (userId) => {
  const { state, dispatch } = useContext(StoreContext);
  const { isLoading, error, best: bestReview, worst: worstReview } = state.userpage;
  const [queries, setQueries] = useState();
  const { data: bestCollection, error: bestError } = useCollection(queries?.[0]);
  const { data: worstCollection, error: worstError } = useCollection(queries?.[1]);

  useEffect(() => {
    if (userId && !isLoading && (bestReview === undefined || worstReview === undefined)) {
      dispatch({ type: 'USERPAGE_FETCH' });
      setQueries([
        firestoreQuery(
          collectionGroup(firestore, 'reviews').withConverter(reviewConverter),
          where('userId', '==', userId),
          orderBy('rating', 'desc'),
          limit(1)
        ),
        firestoreQuery(
          collectionGroup(firestore, 'reviews').withConverter(reviewConverter),
          where('userId', '==', userId),
          orderBy('rating', 'asc'),
          limit(1)
        ),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (bestError || worstError) {
      dispatch({ type: 'USERPAGE_ERROR', payload: bestError ?? worstError });
    }
  }, [bestError, worstError, dispatch]);

  useEffect(() => {
    if (bestCollection && worstCollection) {
      dispatch({
        type: 'USERPAGE_SET',
        payload: { best: bestCollection?.[0] ?? null, worst: worstCollection?.[0] ?? null },
      });
    }
  }, [bestCollection, worstCollection, dispatch]);

  return {
    best: bestReview,
    worst: worstReview,
    isLoading: isLoading,
    error: error,
  };
};

export const useUserReviews = (userId) => {
  const [reviews, setReviews] = useState([]);
  const { items, isLoading, error, next } = usePagination(
    firestoreQuery(
      collectionGroup(firestore, 'reviews').withConverter(reviewConverter),
      where('userId', '==', userId),
      orderBy('rating', 'desc') //.orderBy('timestamp', 'desc')
    )
  );

  useEffect(() => {
    if (items && items.length > 0) {
      setReviews((prevState) => [...prevState, ...items]);
    }
  }, [items]);

  return {
    reviews: reviews,
    isLoading: isLoading,
    error: error,
    next: next,
  };
};

export const useLatestReviews = (icecreamId) => {
  const [reviews, setReviews] = useState([]);
  const { items, isLoading, error, next } = usePagination(
    firestoreQuery(
      collection(doc(firestore, 'icecreams', icecreamId), 'reviews').withConverter(reviewConverter),
      where('hasText', '==', true),
      orderBy('timestamp', 'desc')
    ),
    3
  );

  useEffect(() => {
    if (items && items.length > 0) {
      setReviews((prevState) => [...prevState, ...items]);
    }
  }, [items]);

  return {
    reviews: reviews,
    isLoading: isLoading,
    error: error,
    next: next,
  };
};
