import { useState, useEffect } from 'react';
import { query as firebaseQuery, limit as firebaseLimit, startAt, getDocs } from 'firebase/firestore';

export const usePagination = (baseQuery, limit = 10) => {
  const [items, setItems] = useState();
  const [next, setNext] = useState();
  const [last, setLast] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const cursor = (last) => {
    let query = firebaseQuery(baseQuery, firebaseLimit(limit + 1));
    if (last) {
      query = firebaseQuery(query, startAt(last));
    }
    return query;
  };

  const fetch = () => {
    setIsLoading(true);
    getDocs(cursor(last))
      .then((querySnapshot) => {
        if (querySnapshot.metadata.fromCache && querySnapshot.docs.length === 0) {
          throw new Error('Device is offline and cache was empty');
        }
        setLast(
          querySnapshot.docs.length === limit + 1 ? querySnapshot.docs[querySnapshot.docs.length - 1] : undefined
        );
        const newItems = querySnapshot.docs.slice(0, limit).map((doc) => doc.data());
        setItems(newItems);
      })
      .catch((error) => {
        setError(error);
      })
      .then(() => setIsLoading(false));
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNext(last ? () => () => fetch() : undefined);
    // TODO: wrap functions in useCallback to add as depdency?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return {
    items: items,
    isLoading: isLoading,
    error: error,
    next: next,
  };
};
