import './MyReviewsPage.scss';
import React from 'react';
import { DetailPage } from '../base/Base';
import { Header } from '../../components/header/Header';
import { useUserReviews } from '../../firebase/hooks/UseReview';
import { ReviewList } from '../../components/review/ReviewList';
import { Container } from '../../components/common/Common';
import { useAuth } from '../../firebase/hooks/UseAuth';
import { Button } from '../../components/button/Button';
import { Loading } from '../../components/loading/Loading';
import { ModalError } from '../../components/modal/Modal';

export const MyReviewsPage = () => {
  const { user } = useAuth();
  const { reviews, isLoading, error, next } = useUserReviews(user.uid);

  return (
    <DetailPage label='Recensioner' parent={'/anvandare'} className='my-reviews-page'>
      <Header>
        <h1>Mina recensioner</h1>
      </Header>
      <Container>
        <main>
          <p>Klicka på en glass för att se din recension</p>
          <Loading loading={isLoading} />
          <ReviewList reviews={reviews} />
          {next && (
            <Button loading={isLoading} onClick={() => next()}>
              Visa fler
            </Button>
          )}
        </main>
      </Container>
      <ModalError
        error={error}
        body={
          <>
            <p>Något gick fel.</p>
            <p>&nbsp;</p>
            <p>Pröva igen om en liten stund.</p>
          </>
        }
      />
    </DetailPage>
  );
};
