import { Ratings } from './Ratings';
import { imageUrl } from '../hooks/useSrcset';

export class IceCream {
  constructor(id, name, brand, tags, image = '', isNew = false, numRatings = 0, rating) {
    this.id = id;
    this.name = name;
    this.brand = brand;
    this.tags = tags || [];
    this.image = image;
    this.numRatings = numRatings;
    this.avgRating = rating;
    this.isNew = isNew;
  }

  hasTag(id) {
    return this.tags.includes(id);
  }

  addReview(review) {
    this.avgRating = Ratings.addReview(this.avgRating, review, this.numRatings);
    this.numRatings++;
  }

  get productImage() {
    return this.image ? imageUrl(this, 480, 'webp') : undefined;
  }

  get displayInNews() {
    return this.isNew && this.image;
  }
}

export const iceCreamConverter = {
  toFirestore: (icecream) => {
    return {
      name: icecream.name,
      brand: icecream.brand,
      image: icecream.image,
      tags: icecream.tags || [],
      isNew: icecream.isNew,
      numRatings: 0,
      avgRating: 0,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new IceCream(
      snapshot.id,
      data.name,
      data.brand,
      data.tags,
      data.image,
      data.isNew,
      data.numRatings,
      data.avgRating * Ratings.MAX_RATING
    );
  },
};
