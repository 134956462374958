import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, ButtonGroup } from '../button/Button';
import { Modal } from '../modal/Modal';

export const IOSInstallModal = () => {
  // const [open, setOpen] = useState(() => {
  //   const isIos = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
  //   const isStandaloneMode = 'standalone' in window.navigator && window.navigator.standalone;

  //   if (isIos && !isStandaloneMode) {
  //     const value = localStorage.getItem('ios-install-modal');
  //     if (value === 'false') return false;
  //     const lastShowed = new Date(value);
  //     return new Date() - lastShowed > 1000 * 60 * 60; // 1 hour
  //   }
  //   return false;
  // });
  const [canInstall] = useState(() => {
    try {
      const isIos = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
      const isStandaloneMode = 'standalone' in window.navigator && window.navigator.standalone;
      return isIos && !isStandaloneMode;
    } catch {
      return false;
    }
  });
  const [open, setOpen] = useState(false);

  const onClose = (showAgain) => {
    //localStorage.setItem('ios-install-modal', showAgain ? new Date() : false);
    setOpen(false);
  };

  if (!canInstall) {
    return <></>;
  }

  return (
    <>
      <Button onClick={() => setOpen(true)}>Installera</Button>
      <Modal
        header={
          <h1>
            Installera <FontAwesomeIcon icon={faPlusSquare} />
          </h1>
        }
        body={
          <div>
            <p>Glassduellen kan installeras på din iPhone!</p>
            <p>&nbsp;</p>
            <p style={{ textAlign: 'center', fontSize: '2.5rem' }}>&#x1F4F1;&#x1F973;&#x1F44D;</p>
            <p>&nbsp;</p>
            <p>
              Klicka på delningsknappen i Safari och sedan <em>Lägg till på hemskärmen</em>
            </p>
          </div>
        }
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        actions={
          <ButtonGroup>
            {/* <Button onClick={() => onClose(true)}>Påminn mig senare</Button> */}
            {/* <Button onClick={() => onClose(false)}>Visa inte igen</Button> */}
            <Button onClick={() => onClose(false)}>Okej</Button>
          </ButtonGroup>
        }
      />
    </>
  );
};
