import './UserPage.scss';
import React, { useEffect, useState } from 'react';
import { auth } from '../../firebase/firebase';
import { GenericPage } from '../base/Base';
import { Header } from '../../components/header/Header';
import { Image } from '../../components/image/Image';
import { Container } from '../../components/common/Common';
import { useProfile } from '../../firebase/hooks/UseAuth';
import { useUserStats } from '../../firebase/hooks/UseReview';
import { Button, ButtonNavLink } from '../../components/button/Button';
import { useNavigate } from 'react-router';
import { Input } from '../../components/input/Input';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalError } from '../../components/modal/Modal';
import { IOSInstallModal } from '../../components/tooltip/Install';
import { CoffeeSection } from '../../components/svg/Svg';
import { ReviewModal } from '../../components/review/ReviewList';
import { useIcecream } from '../../firebase/hooks/UseIcecream';

export const UserPage = () => {
  const navigate = useNavigate();
  const { profile, update, isLoading, error } = useProfile();

  const onLogout = () => {
    auth
      .signOut()
      .then(() => {
        navigate('/loggain');
      })
      .catch((error) => {});
  };

  return (
    <GenericPage className='user-page'>
      <Header avatar={'ice-tester-1'}>
        <h1>Användare</h1>
        <span>{profile.alias}</span>
      </Header>

      <main>
        <CoffeeSection>
          <Container small={true}>
            <h2>Statsistik</h2>
            <Stats profile={profile} />
          </Container>
        </CoffeeSection>
        <Container small={true}>
          <h2>Profil</h2>
          {profile && !isLoading && !error && (
            <ProfileSettings
              profile={profile}
              disabled={isLoading}
              onUpdate={(data) => {
                update(data);
              }}
            />
          )}

          <ul className='user-page__actions'>
            <li>
              <Button onClick={onLogout}>Logga ut</Button>
            </li>
            <li>
              <IOSInstallModal />
            </li>
          </ul>

          <h2>Integritet</h2>
          <ul className='user-page__actions'>
            {/* <li>
                <ButtonNavLink to='/kontakt'>Kontakt</ButtonNavLink>
              </li> */}
            <li>
              <ButtonNavLink to='/anvandarvillkor'>Användarvillkor</ButtonNavLink>
            </li>
            <li>
              <ButtonNavLink to='/integritetspolicy'>Integritetspolicy</ButtonNavLink>
            </li>
          </ul>
        </Container>
        <ModalError error={error} />
      </main>
    </GenericPage>
  );
};

const ProfileSettings = ({ profile, onUpdate, disabled }) => {
  const [name, setName] = useState('');
  const [anonymous, setAnonymous] = useState(false);

  useEffect(() => {
    setName(profile.alias || '');
    setAnonymous(profile.anonymous || false);
  }, [profile]);

  return (
    <div className='profile-settings'>
      <div>
        <h3>Alias</h3>
        <Input
          value={name}
          onChange={(event) => setName(event.target.value)}
          maxLength={40}
          disabled={disabled}
        ></Input>
      </div>
      {/* <div>
        <h3>Anonym</h3>
        <Toggle checked={anonymous} onChange={(event) => setAnonymous(event.target.checked)} />
        <div>
          <small>Dölj ditt namn på recensioner</small>
        </div>
      </div> */}

      {/* <div>
        <h3>Email</h3>
        <Input value={user.email} disabled readOnly></Input>
      </div> */}

      <Button
        onClick={() => onUpdate({ alias: name, anonymous })}
        disabled={name === profile.alias && anonymous === profile.anonymous}
        loading={disabled}
      >
        Spara <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>
      </Button>
    </div>
  );
};

const Stats = ({ profile }) => {
  const { best, worst } = useUserStats(profile.id);
  const [modal, setModal] = useState();

  return (
    <>
      <div className='profile-stats'>
        <ProfileStat title='Toppen' review={best} onClick={(review, icecream) => setModal([review, icecream])} />
        <div>
          <h3>Recensioner</h3>
          <div>{profile.totalReviews}</div>
        </div>
        <ProfileStat title='Botten' review={worst} onClick={(review, icecream) => setModal([review, icecream])} />
      </div>
      <ButtonNavLink to='recensioner'>Mina recensioner</ButtonNavLink>
      <ReviewModal review={modal?.[0]} icecream={modal?.[1]} onClose={() => setModal(undefined)}></ReviewModal>
    </>
  );
};

const ProfileStat = ({ title, review, onClick }) => {
  const { icecream } = useIcecream(review?.icecreamId);

  return (
    <div onClick={() => (review && icecream ? onClick(review, icecream) : null)} style={{ cursor: 'pointer' }}>
      <h3>{title}</h3>
      <div>{review && <Image icecream={icecream} small={true} />}</div>
    </div>
  );
};
