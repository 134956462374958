import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../store/Store';
import { FirebaseContext } from '../context';
import { firestore } from '../firebase';
import { useMutation } from './UseFirestore';

export const useAuth = () => {
  return useContext(FirebaseContext);
};

export const useProfile = () => {
  const { user } = useAuth();
  const { state, dispatch } = useContext(StoreContext);
  const { isLoading, error } = state.user;
  const id = user.uid;

  const { mutate: update, isLoading: updateIsLoading, error: updateError } = useMutation('update', {
    collection: 'users',
    id: id,
    onSuccess: (value) => {
      dispatch({ type: 'USER_UPDATE', payload: value });
    },
  });

  useEffect(() => {
    if ((isLoading || state.user.initialized) && state.user.id === id) {
      // TODO: reset user on logout instead.
    } else {
      dispatch({ type: 'USER_FETCH' });
      getDoc(doc(firestore, 'users', id))
        .then((doc) => {
          if (doc.exists()) {
            const data = doc.data();
            data.id = doc.id;
            // if (!data.alias) {
            //   data.alias = user.displayName;
            // }
            dispatch({ type: 'USER_SET', payload: data });
          } else {
            dispatch({ type: 'USER_ERROR', payload: { message: 'Det finns ingen användare med detta id.' } });
          }
        })
        .catch((error) => dispatch({ type: 'USER_ERROR', payload: error }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    profile: state.user,
    isLoading: isLoading || updateIsLoading,
    error: error || updateError,
    update,
  };
};

export const useProfileCreatedListener = () => {
  const { user } = useAuth();
  const [exists, setExists] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (user) {
      setIsLoading(true);
      const unsubscribe = onSnapshot(
        doc(firestore, 'users', user.uid),
        (querySnapshot) => {
          if (querySnapshot.exists()) {
            setExists(true);
            setIsLoading(false);
          }
        },
        (error) => {
          setError(error);
          setIsLoading(false);
        }
      );
      return () => unsubscribe();
    }
  }, [user]);

  return {
    user: user,
    exists: exists,
    isLoading: isLoading,
    error: error,
  };
};
