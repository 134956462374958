export const timeSince = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000);
  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + ' år sedan';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + (interval > 2 ? ' månader sedan' : ' månad sedan');
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + (interval > 2 ? ' dagar sedan' : ' dag sedan');
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + (interval > 2 ? ' timmar sedan' : ' timme sedan');
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + (interval > 2 ? ' minuter sedan' : ' minut sedan');
  }
  return Math.floor(seconds) + (interval > 2 ? ' sekund sedan' : ' sekunder sedan');
};
