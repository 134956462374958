import './Sidescroll.scss';
import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export const Sidescroll = ({ children, style }) => {
  const ref = useRef();

  const scroll = (next) => {
    const current = ref.current.scrollLeft;
    let amount = 11 * 16 * 3;
    if (next) amount *= -1;
    ref.current.scrollTo({
      left: current + amount,
      behavior: 'smooth',
    });
  };

  return (
    <div className='sidescroll' style={style}>
      <div className='sidescroll__arrow sidescroll__arrow--left'>
        <FontAwesomeIcon icon={faChevronLeft} onClick={() => scroll(true)}></FontAwesomeIcon>
      </div>
      <div ref={ref} className='sidescroll__content'>
        {children}
      </div>
      <div className='sidescroll__arrow sidescroll__arrow--right'>
        <FontAwesomeIcon icon={faChevronRight} onClick={() => scroll(false)}></FontAwesomeIcon>
      </div>
    </div>
  );
};

export const SidescrollChild = ({ children, style }) => {
  return (
    <div className='sidescroll-child' style={style}>
      {children}
    </div>
  );
};
