import { useEffect, useState } from 'react';
import { BRANDS } from '../components/brand/Brand';

const bucket = 'https://firebasestorage.googleapis.com/v0/b/ice-off.appspot.com/o/';
const token = '4db45e16-da90-4ea8-9f23-1acd9c00fba1';

export const imageUrl = (icecream, size, ext = 'webp') => {
  const [name] = icecream.image.split('.');
  const storage = BRANDS[icecream.brand].storage;
  const fullName = `glass/${storage}/sizes/${name}`;
  return `${bucket}${encodeURIComponent(fullName)}_${size}x${size}.${ext}?alt=media&token=${token}`;
};

export const fallbackBase = (icecream) => {
  if (icecream.tags.filter((tag) => ['ice', 'stick', 'cone'].includes(tag)).length > 0) {
    const tag = icecream.tags[0];
    return `/assets/images/placeholder/icecream-${tag}${tag === 'ice' ? '' : '-1'}`;
  }
  return '/assets/images/placeholder/icecream-ice';
};

// https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images
export const useSrcSet = (icecream, small = false) => {
  const [state, setState] = useState({ src: '', srcSet: '', sizes: '160px', type: 'image/webp' });

  useEffect(() => {
    const output = {};
    output.type = state.type;
    output.sizes = state.sizes;

    if (!icecream) {
      return;
    }

    if (icecream.image) {
      output.src = imageUrl(icecream, 160);

      if (!small) {
        output.srcSet = `${imageUrl(icecream, 160)} 160w, ${imageUrl(icecream, 320)} 320w, ${imageUrl(
          icecream,
          480
        )} 480w
      `;
      }
    } else {
      const base = fallbackBase(icecream);
      output.src = `${base}_160x160.webp`;

      if (!small) {
        output.srcSet = `
          ${base}_480x480.webp 480w,
          ${base}_320x320.webp 320w,
          ${base}_160x160.webp 160w
        `;
      }
    }

    setState(output);
  }, [icecream, small]);

  return state;
};
