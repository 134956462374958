import './IcecreamPage.scss';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useLocation } from 'react-router-dom';
import { IcecreamHeader } from '../../components/icecream/IcecreamHeader';
import { ButtonNavLink } from '../../components/button/Button';
import { Container } from '../../components/common/Common';
import { useIcecream } from '../../firebase/hooks/UseIcecream';
import { DetailPage } from '../base/Base';
import { LatestReviewsList } from '../../components/review/ReviewList';
import { Brand } from '../../components/brand/Brand';
import { Loading } from '../../components/loading/Loading';
import { ModalError } from '../../components/modal/Modal';
import { useAuth } from '../../firebase/hooks/UseAuth';
import { RenderOnEnter } from '../../hooks/useEnterViewport';
import { StarRatingInline } from '../../components/star-rating/StarRating';

export const IcecreamPage = () => {
  const { user, isLoading: authIsLoading } = useAuth();
  const { id } = useParams();
  const { icecream, isLoading, error } = useIcecream(id);
  const location = useLocation();

  return (
    <DetailPage label='Glass' parent={'/glass'} className='icecream-page'>
      {icecream && (
        <Helmet>
          <title>
            {icecream.name}, {icecream.brand} | Glassduellen
          </title>
          <link rel='canonical' href={`https://glassduellen.se/glass/${icecream.id}`}></link>
          <meta
            name='description'
            content={`${icecream.name} från ${icecream.brand}, rankad ${icecream.avgRating} eller högre. Se betyg och recensera`}
          />
          <meta content='glassduellen, smak, pris, storlek, intryck, recension' name='keywords'></meta>
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'Product',
              name: icecream.name,
              image: [icecream.productImage],
              brand: {
                '@type': 'Brand',
                name: icecream.brand,
              },
              aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: icecream.avgRating,
                ratingCount: icecream.numRatings,
                bestRating: '5',
                worstRating: '1',
              },
            })}
          </script>
        </Helmet>
      )}
      <IcecreamHeader icecream={icecream} rating={true} />
      <Container>
        <main>
          <ButtonNavLink to={'betygsatt'} state={{ from: location }}>
            Betygsätt
          </ButtonNavLink>
          <Loading loading={isLoading} />
          <IcecreamDetails icecream={icecream} />
          <div className='latest-reviews'>
            <h3>Senaste recensioner</h3>
            {!user && !authIsLoading && <p>Logga in för att se kommentarer</p>}
            {user && (
              <RenderOnEnter>
                <LatestReviewsList id={id} />
              </RenderOnEnter>
            )}
          </div>
        </main>
      </Container>
      <ModalError
        error={error}
        body={
          <>
            <p>Något gick fel.</p>
            <p>&nbsp;</p>
            <p>Pröva igen om en liten stund.</p>
          </>
        }
      />
    </DetailPage>
  );
};

const IcecreamDetails = ({ icecream }) => {
  return (
    <>
      <div className='icecream-details'>
        <div>
          <h3>Varumärke</h3>
          <Brand brand={icecream?.brand} />
        </div>
        <div>
          <h3>Recensioner</h3>
          <span>{icecream?.numRatings}</span>
        </div>
        <div>
          <h3>Betyg</h3>
          <StarRatingInline rating={icecream?.avgRating} />
        </div>
      </div>
    </>
  );
};
