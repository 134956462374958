//import { functions } from '../firebase';
import { firestore } from '../../firebase/firebase';
import { useState, useEffect } from 'react';
import {
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  collection as firestoreCollection,
  setDoc,
  addDoc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';

export const useCollectionSnapshot = (query) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query,
      (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        setData(data);
        setIsLoading(false);
      },
      (error) => {
        setError(error);
        setIsLoading(false);
      }
    );
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data: data,
    isLoading: isLoading,
    error: error,
  };
};

export const useCollection = (query) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (query) {
      setIsLoading(true);
      getDocs(query)
        .then((querySnapshot) => {
          const docs = querySnapshot.docs.map((doc) => doc.data());
          setData(docs);
        })
        .catch((error) => {
          setError(error);
        })
        .then(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return {
    data: data,
    isLoading: isLoading,
    error: error,
  };
};

export const useDoc = (query) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (query) {
      setIsLoading(true);
      getDoc(query)
        .then((doc) => {
          if (doc.exists()) {
            setData(doc.data());
          }
        })
        .catch((error) => setError(error))
        .then(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data: data,
    isLoading: isLoading,
    error: error,
  };
};

export const useMutation = (operation, { collection, id, converter, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const runMutation = (value) => {
    let query = firestoreCollection(firestore, collection);
    if (id) {
      query = doc(query, id);
    }
    if (converter) {
      query = query.withConverter(converter);
    }
    switch (operation) {
      case 'set':
        return setDoc(query, value);
      case 'add':
        return addDoc(query, value);
      case 'update':
        return updateDoc(query, value);
      case 'delete':
        return deleteDoc(query);
      default:
        throw new Error(`Unsupported mutation type ${operation}.`);
    }
  };

  const mutation = (value) => {
    setIsLoading(true);
    return runMutation(value)
      .then(() => {
        onSuccess && onSuccess(value);
        return true;
      })
      .catch((error) => {
        setError(error);
        return false;
      })
      .then((value) => {
        setIsLoading(false);
        return value;
      });
  };

  return {
    mutate: mutation,
    isLoading: isLoading,
    error: error,
  };
};
