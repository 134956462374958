import './Button.scss';
import React from 'react';
import { NavLink } from 'react-router-dom';

export const Button = ({ children, loading, disabled, type, onClick }) => {
  return (
    <button className='button' disabled={loading || disabled} type={type} onClick={onClick}>
      {loading && <div className='button__loading'></div>}
      {children}
    </button>
  );
};

export const ButtonNavLink = ({ children, to, state }) => {
  return (
    <NavLink className='button button-navigation' to={to} state={state}>
      {children}
    </NavLink>
  );
};

export const ButtonGroup = ({ children }) => {
  return <div className='button-group'>{children}</div>;
};

export const Notification = ({ number }) => {
  return <div className='notification'>{number}</div>;
};
