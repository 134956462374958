import './PrivacyPolicyPage.scss';
import React from 'react';
import { Container } from '../../components/common/Common';
import { GenericPage } from '../base/Base';
import { Header } from '../../components/header/Header';

export const PrivacyPolicyPage = () => {
  return (
    <GenericPage className='privacy-policy-page'>
      <Header>
        <h1>Integritetspolicy</h1>
      </Header>
      <Container>
        <main>
          <p>
            <strong>Senast uppdaterad: 2021-06-29</strong>
          </p>

          <p>
            Glassduellen är en onlinetjänst för att recensera, betygsätta och bläddra bland glassar på den svenska
            marknaden. I denna Policy kallas dessa gemensamt för våra Tjänster och för att tillhandahålla dessa behöver
            vi samla in och hantera personlig data. Genom att besöka glassduellen.se och nyttja dess tjänster godkänner
            du de metoder som beskrivs nedan.
          </p>

          <h2>Vilken data vi samlar in</h2>
          <p>Vi samlar in följande data:</p>
          <ul>
            <li>email och namn</li>
            <li>användarnamn och lösenord</li>
            <li>användardata: recensioner</li>
            <li>enhetsinformation, t.ex. IP-adress, programvara eller webbläsare som används</li>
          </ul>

          <h2>Hur vi samlar in din data</h2>
          <p>Vi samlar in och hanterar din data när du:</p>
          <ul>
            <li>Registrerar och hanterar ditt konto</li>
            <li>Publicerar recensioner</li>
            <li>Besöker vår webbsida med webbläsarens cookies</li>
          </ul>
          <p>
            Vi erbjuder inloggning via tredjepartstjänster (e.g. Google, Facebook). När du använder dessa godkänner du
            tjänsten att skicka information till oss (publik profilinformation och email) enligt dina
            integritetsinställningar för den valda tjänsten.
          </p>

          <h2>Hur vi använder din data</h2>
          <p>Vi använder insamlad data för att:</p>
          <ul>
            <li>Sammanställa gemensamma och personliga topplistor</li>
            <li>Visa recensioner</li>
            <li>Auktorisera din användare</li>
          </ul>

          <h2>Hur vi lagrar din data</h2>
          <p>
            Vi behåller din data så länge du behåller ditt konto eller så länge det är nödvändigt för att fullfölja de
            syften datan samlats in för. Du kan när som helst avsluta ditt konto genom att kontakta oss på{' '}
            <a href='mailto:info@glassduellen.se'>info@glassduellen.se</a> från den email som är kopplat till ditt
            konto. All din data kommer då att raderas och/eller anonymiseras.
          </p>
          <p>
            Vi kommer till och från att dela din personliga data med tjänsteleverantörer (data processors) som på
            uppdrag av oss (data controller) lagrar och hanterar data. Tabellen nedan (vilken kan uppdateras) listar
            våra leverantörer samt vilken tjänst de tillhandahåller webbplatsen. Det är möjligt att dem i sin tur
            använder ytterligare tjänster, för mer detaljer hänvisar vi till respektive integritetspolicy.
          </p>

          <table>
            <thead>
              <tr>
                <th>Leverantör</th>
                <th>Tjänster</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Firebase Authentication</td>
                <td>Autentisera konton </td>
              </tr>
              <tr>
                <td>Firebase Hosting</td>
                <td>Webbhotell</td>
              </tr>
              <tr>
                <td>Google Cloud Firestore</td>
                <td>Datavärd och säkerhetskopiering</td>
              </tr>
            </tbody>
          </table>

          <h2>Dina rättigheter för dataskydd</h2>
          <p>Som användare har du flera rättigheter till dina personuppgifter, följande är:</p>
          <ul>
            <li>
              <span>Rätten till åtkomst</span> - Du kan begära en kopia av dina personuppgifter som vi lagrar om dig.
            </li>
            <li>
              <span>Rätten till rättelse</span> - Du kan begära rättelse eller fullföljande av dina personuppgifter som
              du misstänker är felaktiga eller ofullständiga.
            </li>
            <li>
              <span>Rätten till radering</span> - Du kan begära radering av dina personuppgifter, under särskilda
              förhållanden.
            </li>
            <li>
              <span>Rätten till begränsad behandling</span> - Du kan begära begränsad behandling av dina
              personuppgifter, under särskilda förhållanden.
            </li>
            <li>
              <span>Rätten att invända behandling</span> - Du kan invända dig mot vår behandling av din personliga data,
              under särskilda förhållanden.
            </li>
            <li>
              <span>Rätten till dataportabilitet</span> - Du kan begära överföring av dina personuppgifter vi lagrar om
              dig till en annan organisation, eller dig, under särskilda förhållanden.
            </li>
          </ul>
          <p>
            För att begära åtkomst till personuppgifter eller begäran av andra rättigheter behöver du bekräfta din
            identitet. Detta är en säkerhetsåtgärd för att inte lämna ut personuppgifter till någon som inte har rätt
            att ta del av dem. I första hand bör du kontakta oss från den email som är kopplad till ditt användarkonto.
            Kontaktinformation hittar du längre ned i denna policy.
          </p>

          <h2>Cookies</h2>
          <p>
            Cookies är små textfiler som sparas på din enhet när du besöker en webbplats, även liknande teknologier
            förekommer. Informationen kan handla om dig, dina preferenser eller din enhet och används mest för att
            webbplatsen ska fungera. Cookies hjälper oss att känna igen dig när du återvänder till webbplatsen. Om du
            blockerar vissa typer av cookies kan det påverka din upplevelse av webbplatsen och de tjänster som erbjuds.
          </p>

          <h2>Hur vi använder cookies</h2>
          <p>
            Cookies kan exempelvis användas för att förbättra din upplevelse och lagra särskild information medan du
            navigerar på webbplatsen. Vi använder dem i följande syften:
          </p>
          <ul>
            <li>
              <span>Auktorisering</span> - Vi använder tredjepartstjänster som kan använda cookies för att logga in dig
              via deras tjänster.
            </li>
          </ul>

          <h2>Vilka kategorier av cookies vi använder</h2>
          <p>Det finns flera olika kategorier av cookies, vi använder endast:</p>
          <ul>
            <li>
              <span>Nödvändiga</span> - Utan dessa cookies skulle inte webbplatsen eller de tjänster du efterfrågat
              fungera.
            </li>
          </ul>

          <h2>Samtycke till cookies</h2>
          <p>
            För att lagra cookies eller använda liknande teknologier behöver vi ditt godkännande. Det gäller dock inte
            där dessa är förutsättande för att vi ska kunna förse dig med den tjänst som du efterfrågar, exempelvis
            inloggning till vår webbplats.
          </p>

          <h2>Policy ändringar</h2>
          <p>
            Denna policy kan uppdateras i framtiden. Om vi anser att vissa ändringar är väsentliga meddelar vi dig genom
            att visa ett meddelande på webbplatsen eller/och publicera en uppdaterad Policy. Senast uppdaterade datum
            visas alltid högst upp i denna policy.
          </p>

          <h2>Kontakt</h2>
          <p>
            Om du har några frågor om denna integritetspolicy, din data eller vill begära en av dina rättigheter för
            dataskydd så tveka inte att höra av dig till oss.
          </p>
          <p>
            <a href='mailto:info@glassduellen.se'>info@glassduellen.se</a>
          </p>
        </main>
      </Container>
    </GenericPage>
  );
};
