import './TermsOfServicePage.scss';
import React from 'react';
import { Container } from '../../components/common/Common';
import { GenericPage } from '../base/Base';
import { Header } from '../../components/header/Header';

export const TermsOfServicePage = () => {
  return (
    <GenericPage className='terms-of-service-page'>
      <Header>
        <h1>Användarvillkor</h1>
      </Header>
      <Container>
        <main>
          <p>
            <strong>Senast uppdaterad: 2021-07-03</strong>
          </p>

          <p>
            Glassduellen är en onlinetjänst för att recensera, betygsätta och bläddra bland glassar på den svenska
            marknaden. Med datan kan vi framställa topplistor och användare kan följa andra användare för att se deras
            recensioner. Vårat mål är att skapa Sveriges största glasstest!
          </p>

          <p>
            Läs noggrant igenom Användarvillkoren före använding av de Tjänster som Glassduellen tillhandahåller. Genom
            att använda Tjänsterna godkänner du dessa Användarvillkor och förbinder dig att följa dem. Om du inte
            accepterar villkoren har du inte behörighet till tjänsterna.
          </p>

          <h2>Immateriella rättigheter</h2>
          <p>
            Glassduellen innehar, kontrollerar eller licensierar de immateriella rättigheterna till text, bild, design,
            källkod och övrigt material som görs tillgängligt för dig genom Tjänsterna. Sådant material får inte
            användas på annat sätt än inom ramen för normal användning av Tjänsterna.
          </p>
          <p>
            Du får inte kopiera, reproducera, publicera, ladda upp, skicka, distribuera, sälja eller på annan grund
            kommersiellt använda material eller information från webbplatsen utan skriftligt tillstånd. Det är inte
            heller tillåtet att använda manuella eller automatiserade programvaror, devicer eller andra processer
            (inkluderar men är inte begränsat till robotar, spiders, scrapers, crawlers, mining och liknande
            teknologier) för systematisk användning eller nedladdning av innehåll på webbplatsen. (Undantag ges för
            publika operatörer av sökmotorer i syfte att skapa publikt tillgängliga sökindex av innehåll)
          </p>

          <p>All otillåten användning medför ersättningsskyldighet.</p>

          <h2>Förutsättningar för att använda Tjänsterna</h2>
          <p>
            För att använda tjänsterna måste du vara minst 13 år gammal. Tjänsterna tillhandahålls inte till personer
            eller företag som tidigare har brutit mot Användarvillkoren. Om du registrerar ett företag som användare
            intygar du att du har behörighet att binda företaget till dessa Användarvillkor. Du får inte använda
            webbplatsen för att skapa en liknande eller konkurrerande webbsida.
          </p>
          <p>
            För att använda Tjänsterna behöver du antingen logga in via en tredje part (Facebook eller Google) eller
            fylla i ett formulär för att skapa ett användarkonto utan tredjeparts-inloggning.
          </p>

          <h2>Användarens skyldigheter</h2>
          <p>Användaren måste hela tiden följa dessa kritierier vid användande av Tjänsterna:</p>
          <ul>
            <li>Respektera och följa användarvillkoren.</li>
            <li>Förse Glassduellen med korrekt kontoinformation såsom namn och e-postadress.</li>
            <li>
              Undvika att publicera personuppgifter om sig själv och andra, särskilt känsliga kategorier av
              personuppgifter (såsom hälsa, missbruk eller allergier) och innehåll som är stötande, olämpligt eller
              olagligt eller på annat sätt strider mot dessa villkor.
            </li>
            <li>
              Undvika att publicera innehåll som kränker en annan persons rättigheter (inklusive immateriella
              rättigheter och rättigheter för integritet och publicitet).
            </li>
          </ul>
          <p>
            Då ovanstående villkor inte följs kan användarkontot när som helst inaktiveras och förbjudas från fortsatt
            användning av Tjänsterna. Inaktiveringen kan ske med omedelbar verkan för Glassduellens eget gottfinnande.
          </p>

          <p>
            Användaren är ensamt ansvarig för allt innehåll i recensioner, publicering av bilder och information som
            görs tillgängligt på webbplatsen och allmänheten.
          </p>
          <p>
            Användaren är ensamt ansvarig för alla interaktioner med andra användare eller andra tredjepartsleverantörer
            i samband med webbplatsen.
          </p>

          <h2>Användargenererat innehåll</h2>
          <p>
            Glassduellen låter användare bidra med recensioner som till stor del bygger hela idéen, att skapa Sveriges
            största glasstest. För att uppnå en så bra upplevelse som möjligt har vi samlat några riktlinjer som ska
            följas:
          </p>
          <ul>
            <li>
              <span>Olämpligt innehåll</span> - Vi accepterar inte hat, trakasserier, hot, förlolämpningar eller
              innehåll som är pornografiskt, obscent eller våldsamt eller på annat sätt är olämpligt enligt
              Glassduellens egna uppfattning.
            </li>
            <li>
              <span>Reklam</span> - Kampanjer eller annat innehåll i marknadssyften är inte tillåtet utan skriftligt
              tillstånd. Kontaka oss om du har innehåll som du vill dela på webbplatsen.
            </li>
            <li>
              <span>Integritet</span> - Undvik att nämna andra människors namn eller privata uppgifter.
            </li>
            <li>
              <span>Intressekonflikt</span> - Om du är ansluten till en glasstillverkare eller återförsäljare påminner
              vi dig om att dina bidrag ska vara opartiska och objektiva. Att exempelvis betygsätta alla dina egna
              produkter med högsta betyg och alla konkurrenter med lägsta betyg är inte tillåtet. Kunder och nätverk får
              gärna uppmuntras till att recensera och betygsätta men du får inte påverka hur dem gör det. Glassduellen
              bedömer själva om innehåll är partiskt och kan enligt eget gottfinnande plocka bort hela eller delar av
              ditt innehåll.
            </li>
            <li>
              <span>Immateriella rättigheter</span> - Kopiera inte ditt innehåll från andra - skriv med egna ord!
            </li>
          </ul>

          <h2>Ansvarsbegränsning</h2>
          <p>
            Den typ av Tjänster som Glassdullen tillhandahåller åtar sig i allmänhet inget ansvar, varpå potentiellt
            ansvar ligger på användarna.
          </p>
          <p>
            Glassduellen kan inte göras ansvarigt för skador eller förlust som direkt eller indirekt orsakas av
            användning av webbplatsen och dess innehåll.
          </p>
          <p>
            Om Glassduellen i extraordinära fall skulle vara ansvarigt begränsas sådant ansvar, inga indirekta skador
            såsom utebliven vinst, förlust av data eller följdkostnader täcks.
          </p>
          <p>
            Glassduellen är aldrig ansvarigt för fel på plattformen, underliggande programvara, tjänsterna eller någon
            information som tillhandahålls av användare. Plattformen och alla dess funktioner tillhandahålls användaren
            i befintligt skick och Glassduellen garanterar inte att användningen av dessa varken är felfri, pålitlig
            eller kontinuerlig.
          </p>
          <p>
            Glassduellen ger ingen garanti för sanningsgraden eller noggrannheten av några recensioner eller detaljer
            angående glassar.
          </p>

          <h2>Varaktighet och uppsägning</h2>
          <p>Användarvillkoren gäller så länge användarkontot är aktivt.</p>
          <p>
            Glassduellen kan med omedelbar verkan och valfri anledning avsluta ett konto när användaren bryter mot
            reglerna i dessa villkor.
          </p>
          <p>
            Glassduellens rättigheter till användargenererat innehåll påverkas inte av uppsägningen av
            Användarvillkoren.
          </p>
          <p>
            Om du vill säga upp detta avtal kan du när som helst avsluta ditt konto, kontaka oss via{' '}
            <a href='mailto:info@glassduellen.se'>info@glassduellen.se</a>
          </p>

          <h2>Ändringar i Användarvillkoren</h2>
          <p>
            Användarvillkoren kan komma att ändras från tid till annan enligt Glassduellens gottfinnande, följt av ett
            meddelande till användaren vid väsentliga förändringar. Användare är bundna till alla ändringar av villkoren
            när de använder webbplatsen efter att ändringar har publicerats.
          </p>

          <h2>Tillämplig lag och tvistelösning</h2>
          <p>
            Svensk lag ska tillämpas på Tjänsterna och dessa Användarvillkor. Tvister ska avgöras av svenska domstolar
            med Stockholms tingsrätt som första instans.
          </p>

          <h2>Kontakt</h2>
          <p>
            <a href='mailto:info@glassduellen.se'>info@glassduellen.se</a>
          </p>
        </main>
      </Container>
    </GenericPage>
  );
};

/* 

Creates a legally binding contract,
Prevents abuses,
Protects intellectual property,
Allows you to terminate abusive accounts, and
Limits your liability


*/
