import './Brand.scss';

export const BRANDS = {
  'GB Glace': {
    name: 'GB Glace',
    short: 'GB',
    color: '#fc0500',
    storage: 'GB',
  },
  'Triumf Glass': {
    name: 'Triumf Glass',
    short: 'Triumf',
    color: '#f39201',
    storage: 'Triumf',
  },
  'SIA Glass': {
    name: 'SIA Glass',
    short: 'SIA',
    color: '#d60365',
    storage: 'Sia',
  },
  Hemglass: {
    name: 'Hemglass',
    short: 'Hemglass',
    color: '#45c2f0',
    storage: 'Hemglass',
  },
  Fazer: {
    name: 'Fazer',
    short: 'Fazer',
    color: '#15266b',
    storage: 'Triumf',
  },
  '3Vänner': {
    name: '3Vänner',
    short: '3Vänner',
    color: '#ff671a',
    storage: '3Vänner',
  },
  Barebells: {
    name: 'Barebells',
    short: 'Barebells',
    color: '#98d4e8',
  },
};

export const BRANDS_ARRAY = [
  BRANDS['Barebells'],
  BRANDS['Fazer'],
  BRANDS['GB Glace'],
  BRANDS['Hemglass'],
  BRANDS['SIA Glass'],
  BRANDS['Triumf Glass'],
  BRANDS['3Vänner'],
];

export const Brand = ({ brand, full = true }) => {
  return (
    <div className='brand-tag' style={{ backgroundColor: BRANDS[brand]?.color }}>
      {full ? BRANDS[brand]?.name : BRANDS[brand]?.short}
    </div>
  );
};

export const BrandSelector = ({ value, onChange }) => {
  return (
    <div>
      <label htmlFor='brands'>Varumärke</label>
      <select name='brands' id='brands' value={value} onChange={(event) => onChange(event.target.value)}>
        <option key='empty' value=''>
          Select
        </option>
        {BRANDS_ARRAY.map((brand) => (
          <option key={brand.name} value={brand.name}>
            {brand.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export const BrandFilter = ({ value, onChange }) => {
  return (
    <div className='brand-filter'>
      {BRANDS_ARRAY.map((brand) => {
        const isChecked = value.includes(brand.name);
        return (
          <div key={brand.name}>
            <input
              type='checkbox'
              name='brands'
              value={brand.name}
              id={brand.name}
              checked={isChecked}
              onChange={() => onChange(brand.name, !isChecked)}
            />
            <label
              htmlFor={brand.name}
              className='brand-tag'
              style={(isChecked && { backgroundColor: BRANDS[brand.name]?.color }) || {}}
            >
              {brand.name}
            </label>
          </div>
        );
      })}
    </div>
  );
};
