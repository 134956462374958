import { firestore } from '../../firebase/firebase';
import { useState, useEffect, useContext } from 'react';
import { iceCreamConverter } from '../../models/IceCream';
import { useCollectionSnapshot } from './UseFirestore';
import { useApp } from './UseApp';
import { StoreContext } from '../../store/Store';
import { collection, query as firestoreQuery, orderBy } from 'firebase/firestore';

export const useAllIcecreams = () => {
  const { state, isLoading, error } = useApp();
  const [icecreams, setIcecreams] = useState([]);

  useEffect(() => {
    const data = state.icecreams;
    if (data.ids.length > 0) {
      setIcecreams(data.ids.map((id) => data.entities[id]));
    }
  }, [state]);

  return {
    icecreams: icecreams,
    isLoading: isLoading,
    error: error,
  };
};

// export const useIcecreams = (ids) => {
//   const { icecreams, isLoading, error } = useAllIcecreams();
//   const [query] = useState(
//     firestore
//       .collection('icecreams')
//       .where(firebase.firestore.FieldPath.documentId(), 'in', ids)
//       .limit(10)
//       .withConverter(iceCreamConverter)
//   );
//   const { data, isLoading, error } = useCollection(query);

//   return {
//     icecreams: data,
//     isLoading: isLoading,
//     error: error,
//   };
// };

export const useIcecreamSearch = (search, brands) => {
  const { icecreams, isLoading, error } = useAllIcecreams();
  const [searchResult, setSearchResult] = useState([]);
  const [news, setNews] = useState([]);

  useEffect(() => {
    if (icecreams.length > 0) {
      let results = icecreams;
      if (search) {
        results = results.filter(({ name }) => name.toLowerCase().trim().includes(search.toLowerCase().trim()));
      }
      if (brands && brands.length > 0) {
        results = results.filter(({ brand }) => brands.includes(brand));
      }
      results = results.sort((a, b) => a.name.localeCompare(b.name));
      setSearchResult(results);
    }
  }, [search, icecreams, brands]);

  useEffect(() => {
    if (icecreams.length > 0) {
      setNews(icecreams.filter((icecream) => icecream.displayInNews));
    }
  }, [icecreams]);

  return {
    icecreams: searchResult,
    news,
    count: searchResult.length,
    isLoading: isLoading,
    error: error,
  };
};

export const useToplist = () => {
  const { state, dispatch } = useContext(StoreContext);
  const { icecreams, isLoading, error } = useAllIcecreams();
  const [toplist, setToplist] = useState([]);

  const filters = state.toplist.filters;
  const { minRatings, sortByMostRatings, tags } = filters;

  useEffect(() => {
    if (icecreams && icecreams.length > 0) {
      const query = icecreams
        .filter((icecream) => icecream.numRatings > (minRatings ? 4 : 0))
        .filter((icecream) => {
          if (icecream.tags.length === 0) return tags.other;
          return icecream.tags.some((tag) => tags[tag]);
        })
        .sort((first, second) => {
          if (sortByMostRatings) {
            return second.numRatings - first.numRatings;
          } else {
            const a = first.avgRating;
            const b = second.avgRating;
            if (a > b) return -1;
            if (a < b) return +1;
            return second.numRatings - first.numRatings;
          }
        });
      setToplist(query);
    }
  }, [icecreams, minRatings, sortByMostRatings]);

  return {
    icecreams: toplist,
    isLoading: isLoading,
    error: error,
    filters: filters,
    setFilters: (newFilters) => {
      dispatch({ type: 'TOPLIST_FILTERS_SET', payload: newFilters });
    },
  };
};

export const useIcecreamsListener = () => {
  const [query] = useState(
    firestoreQuery(collection(firestore, 'icecreams').withConverter(iceCreamConverter), orderBy('name'))
  );
  const { data, isLoading, error } = useCollectionSnapshot(query);

  return {
    icecreams: data,
    isLoading: isLoading,
    error: error,
  };
};
