import './Modal.scss';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { Button } from '../button/Button';

export const Modal = ({ header, body, actions, isOpen = true, onRequestClose }) => {
  const ref = useRef();
  if (!isOpen) return <></>;
  return ReactDOM.createPortal(
    <CSSTransition appear={true} in={true} timeout={500} nodeRef={ref} classNames='modal-animation'>
      <div
        ref={ref}
        aria-modal='true'
        className='modal-background'
        onClick={(event) => onRequestClose && event.target.className?.includes('modal-background') && onRequestClose()}
      >
        <dialog className='modal' open>
          <div className='modal__header'>{header}</div>
          <div className='modal__body'>{body}</div>
          {actions && <div className='modal__actions'>{actions}</div>}
        </dialog>
      </div>
    </CSSTransition>,
    window.document.body
  );
};

export const ModalError = ({ error, body, actions }) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!error);
  }, [error]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setOpen(false)}
      header={<h1>Ojoj!</h1>}
      body={body || error?.message}
      actions={actions || <Button onClick={() => setOpen(false)}>Okej!</Button>}
    />
  );
};
