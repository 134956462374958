import './Svg.scss';

export const BottomCurve = () => {
  return (
    <svg
      className='svg-bottom-curve'
      viewBox='0 0 200 112.5'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='none'
    >
      <defs>
        <linearGradient id='gradient'>
          <stop offset='0%' stopColor='var(--theme-color-1)' />
          <stop offset='100%' stopColor='var(--theme-color-2)' />
        </linearGradient>
      </defs>
      <path
        style={{ fill: 'rgba(255, 255, 255, 1)' }}
        d='M 86.322 23.208 C 119.041 23.192 138.278 61.399 163.293 73.776 C 179.939 82.012 199.979 80.572 199.979 80.572 L 200 112.5 L 0 112.5 L 0 0 C 0 0 6.764 37.235 30.979 36.549 C 41.431 36.253 61.913 23.22 86.322 23.208 Z'
      />
    </svg>
  );
};

export const CoffeeSection = ({ children }) => {
  return (
    <section className='svg-coffee-section'>
      <svg
        className='svg-coffee-curve'
        viewBox='0 0 1440 320'
        xmlns='http://www.w3.org/2000/svg'
        preserveAspectRatio='none'
      >
        <path
          style={{ fill: 'var(--background-color)' }}
          d='M0,64L40,90.7C80,117,160,171,240,176C320,181,400,139,480,144C560,149,640,203,720,202.7C800,203,880,149,960,133.3C1040,117,1120,139,1200,144C1280,149,1360,139,1400,133.3L1440,128L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z'
        ></path>
      </svg>
      {children}
      <svg
        className='svg-coffee-curve'
        viewBox='0 0 1440 320'
        xmlns='http://www.w3.org/2000/svg'
        preserveAspectRatio='none'
      >
        <path
          style={{ fill: 'var(--background-color)' }}
          d='M0,64L40,90.7C80,117,160,171,240,176C320,181,400,139,480,144C560,149,640,203,720,202.7C800,203,880,149,960,133.3C1040,117,1120,139,1200,144C1280,149,1360,139,1400,133.3L1440,128L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z'
        ></path>
      </svg>
    </section>
  );
};

export const Mountains = () => {
  return (
    <div className='svg-mountains'>
      <svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 1440 320'>
        <defs>
          <path
            id='mountain_wave_1'
            fill='#fff'
            d='M0,96L40,90.7C80,85,160,75,240,101.3C320,128,400,192,480,202.7C560,213,640,171,720,176C800,181,880,235,960,266.7C1040,299,1120,309,1200,282.7C1280,256,1360,192,1400,160L1440,128L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z'
          ></path>
          <path
            id='mountain_wave_2'
            fill='#fff'
            d='M0,64L40,90.7C80,117,160,171,240,176C320,181,400,139,480,144C560,149,640,203,720,202.7C800,203,880,149,960,133.3C1040,117,1120,139,1200,144C1280,149,1360,139,1400,133.3L1440,128L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z'
          ></path>
        </defs>
      </svg>
      <svg
        id='mountain-1'
        xmlns='http://www.w3.org/2000/svg'
        preserveAspectRatio='none'
        viewBox='0 0 1440 320'
        style={{ bottom: '2rem' }}
      >
        <use href='#mountain_wave_1' fillOpacity='0.3' />
      </svg>
      <svg
        id='mountain-2'
        xmlns='http://www.w3.org/2000/svg'
        preserveAspectRatio='none'
        viewBox='0 0 1440 320'
        style={{ bottom: '1rem' }}
      >
        <use href='#mountain_wave_1' fillOpacity='0.6' />
      </svg>
      <svg id='mountain-3' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 1440 320'>
        <use href='#mountain_wave_2' fillOpacity='1' />
      </svg>
    </div>
  );
};

export const FooterCurve = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
      <defs>
        <linearGradient id='gradient'>
          <stop offset='0%' stopColor='var(--theme-color-1)'></stop>
          <stop offset='100%' stopColor='var(--theme-color-2)'></stop>
        </linearGradient>
      </defs>
      <path
        fill='url(#gradient)'
        fillOpacity='1'
        d='M0,32L40,42.7C80,53,160,75,240,69.3C320,64,400,32,480,26.7C560,21,640,43,720,48C800,53,880,43,960,85.3C1040,128,1120,224,1200,245.3C1280,267,1360,213,1400,186.7L1440,160L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z'
      ></path>
    </svg>
  );
};
