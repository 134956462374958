import './ReviewPage.scss';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { StarRating } from '../../components/star-rating/StarRating';
import { Review } from '../../models/Review';
import { IcecreamHeader } from '../../components/icecream/IcecreamHeader';
import { Container } from '../../components/common/Common';
import { ModalError } from '../../components/modal/Modal';
import { useIcecream } from '../../firebase/hooks/UseIcecream';
import { usePostReview, useReview } from '../../firebase/hooks/UseReview';
import { useAuth } from '../../firebase/hooks/UseAuth';
import { DetailPage } from '../base/Base';
import { Button, ButtonNavLink } from '../../components/button/Button';
import { Textarea } from '../../components/input/Input';
import { Loading } from '../../components/loading/Loading';
import { StoreContext } from '../../store/Store';

export const ReviewPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();

  const { icecream, isLoading, error } = useIcecream(id);
  const { review: existingReview, isLoading: reviewIsLoading, error: reviewError } = useReview(id, user.uid);
  const [review, setReview] = useState();

  return (
    <DetailPage label='Betygsätt' parent={'/glass/' + id} className='review-page'>
      <IcecreamHeader icecream={icecream} />
      <Container>
        <main>
          <Loading loading={isLoading || reviewIsLoading} />
          {review ? (
            <Thanks review={review} />
          ) : !reviewIsLoading ? (
            <ReviewForm userId={user.uid} icecream={icecream} setReview={setReview} disabled={!!existingReview} />
          ) : (
            <></>
          )}
        </main>
      </Container>

      <ModalError
        error={existingReview}
        body={<p>Du har redan publicerat en recension för {icecream?.name}.</p>}
        actions={
          <Button
            onClick={() => {
              const to = location.state?.from?.pathname || '/glass/' + id;
              navigate(to, { state: { from: location.state?.from?.state?.from } });
            }}
          >
            Tillbaka
          </Button>
        }
      />
      <ModalError
        error={error || reviewError}
        body={
          <>
            <p>Något gick fel.</p>
            <p>&nbsp;</p>
            <p>Pröva igen om en liten stund.</p>
          </>
        }
      />
    </DetailPage>
  );
};

const Thanks = ({ review }) => {
  return (
    <div className='review-thanks'>
      <h2>Din recension har publicerats!</h2>
      <div className='ratings'>
        <div>
          <StarRating value={review.rating} />
        </div>
      </div>
      <ButtonNavLink to={'/'}>Till Topplistan</ButtonNavLink>
    </div>
  );
};

const ReviewForm = ({ userId, icecream, setReview, disabled }) => {
  const { dispatch } = useContext(StoreContext);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [isValid, setIsValid] = useState();
  const { add, isLoading, error } = usePostReview(userId, icecream?.id);

  const onSubmit = (event) => {
    event.preventDefault();
    const review = new Review(undefined, icecream.id, userId, undefined, undefined, undefined, comment, rating);
    add(review).then((successful) => {
      if (successful) {
        setReview(review);
        icecream.addReview(review);
        dispatch({ type: 'CREATE_ICECREAM_REIVEW', payload: icecream });
      }
    });
  };

  const onChange = (value) => {
    setRating(value);
    setIsValid(value > 0);
  };

  return (
    <>
      <h2>Vad tyckte du om glassen?</h2>
      <form className='review-form' onSubmit={onSubmit}>
        <div className='ratings'>
          <Rating
            name={
              <>
                Betyg
                {/* Betyg <SizeTooltip /> */}
              </>
            }
            onChange={(v) => onChange(v)}
            disabled={disabled}
          />
        </div>
        <Comment value={comment} onChange={(event) => setComment(event.target.value)} disabled={disabled} />
        <Button loading={isLoading} disabled={!isValid || disabled} type='submit'>
          Publicera
        </Button>
      </form>
      <ModalError
        error={error}
        body={
          <>
            <p>Något gick fel när vi skulle publicera din recension.</p>
            <p>&nbsp;</p>
            <p>Pröva igen om en liten stund.</p>
          </>
        }
      />
    </>
  );
};

const Rating = ({ name, onChange, disabled }) => {
  return (
    <div>
      <h3>{name}</h3>
      <StarRating onChange={onChange} editable={true} disabled={disabled} />
    </div>
  );
};

const Comment = ({ value, onChange, disabled }) => {
  return (
    <div className='comment'>
      <h3>Kommentar (valfritt)</h3>
      <Textarea placeholder='...' maxLength={250} value={value} onChange={onChange} disabled={disabled}></Textarea>
    </div>
  );
};
